<template>
  <div>認証中です、しばらくお待ちください</div>
</template>

<script>
export default {
  name: 'Authorization',
  components: {},
  data: function () {
    return {
    }
  },
  async created() {
    const user_id = this.$route.params.user_id;
    const remember_token = this.$route.params.remember_token;
    this.authorization(user_id, remember_token);

  },
  methods: {
    async authorization(user_id, remember_token) {
      const response = await this.$http.put('authorize', {
        user_id, remember_token
      });
      await this.$store.dispatch('signIn', response.data);
      this.$router.push({name: 'artist'});
    }
  }
}
</script>
